import useSettings from 'app/hooks/useSettings';
import logo from '../assets/images/logo.jpg'

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <img src={logo} alt="" width={40} height={40} />
  );
};

export default MatxLogo;
