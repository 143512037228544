import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes";
import "../fake-db";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "./redux/reducer/auth";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));

const App = () => {
  const content = useRoutes(routes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && user) {
      dispatch(loginAction(user));
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          <ToastContainer />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
